/* @define Grid */

.Grid {
  --grid-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--grid-gap);
}

.Grid-item {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  overflow-x: hidden; /* ensure unwrapped text doesn't break the grid */
}

.Grid--1 .Grid-item {
  flex-basis: 100%;
}

.Grid--2 .Grid-item {
  flex-basis: calc(100% / 2 - var(--grid-gap));
}

.Grid--3 .Grid-item {
  flex-basis: calc(100% / 3 - var(--grid-gap));
}

.Grid--4 .Grid-item {
  flex-basis: calc(100% / 4 - var(--grid-gap));
}

.Grid--5 .Grid-item {
  flex-basis: calc(100% / 5 - var(--grid-gap));
}

.Grid--6 .Grid-item {
  flex-basis: calc(100% / 6 - var(--grid-gap));
}

.Grid--growItems .Grid-item {
  flex-grow: 100;
}

.Grid--narrowGutter {
  --grid-gap: 10px;
}

@media (--mediumWidth) {
  .Grid .Grid-item {
    flex-basis: calc(50% - var(--grid-gap));
  }

  .Grid--4 .Grid-item {
    flex-basis: calc(100% / 3 - var(--grid-gap));
  }

  .Grid--5 .Grid-item,
  .Grid--6 .Grid-item {
    flex-basis: calc(100% / 4 - var(--grid-gap));
  }

  .Grid--min3 .Grid-item {
    flex-basis: calc(100% / 3 - var(--grid-gap));
  }

  .Grid--min4 .Grid-item {
    flex-basis: calc(100% / 4 - var(--grid-gap));
  }
}

@media (--smallWidth) {
  .Grid {
    --grid-gap: 10px;
  }

  .Grid .Grid-item {
    flex-basis: 100%;
  }

  .Grid--min2 .Grid-item {
    flex-basis: calc(50% - var(--grid-gap));
  }

  .Grid--min3 .Grid-item {
    flex-basis: calc(100% / 3 - var(--grid-gap));
  }

  .Grid--min4 .Grid-item {
    flex-basis: calc(100% / 4 - var(--grid-gap));
  }
}
